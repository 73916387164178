import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocumentList = (currentPage, pageSize, searchValue, projectId, sortField, sortOrder) => {
  let url = `/DocumentType/GetDocumentList?projectId=${projectId}&currentPage=${currentPage}&pageSize=${pageSize}&sortOrder=${sortOrder}`;
  if (searchValue) {
    url += `&searchValue=${searchValue}`;
  };
  if (sortField) {
    url += `&sortField=${sortField}`;
  };

  const data = useQuery(
    ["documentList", currentPage, pageSize, searchValue, projectId, sortField, sortOrder],
    () =>
      axiosClient.get(url),
    { enabled: !!projectId }
  );

  return data;
};
export default useGetDocumentList;
